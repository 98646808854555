import React from 'react'

function Services() {
  return (
    <section id='services' className='md:mx-24 mx-4 mt-20 relative z-10'>
      <h1 className='text-2xl md:text-5xl font-bold text-center md:text-start'>Services</h1>
      <div className='my-10'>
        <h1 className='text-xl md:text-3xl font-bold my-5 text-center md:text-start'>Fabric & garment design</h1>
        <p className='text-[10px] md:text-sm'>
              Our dynamic design team stands poised to showcase the freshest fashion inclinations and styles to our clientele, operating seamlessly from our Istanbul and London offices. We prioritize responsiveness to the evolving needs of our customers, ensuring their preferences are met with precision.
              <br></br>
              <br></br>
              Utilizing a multifaceted approach, we meticulously analyze trends by keeping abreast of trendsetters, participating in Fashion weeks and trade fairs, and closely monitoring the latest runway shows and fashion labels. This enables us to curate the ideal fabric selections and styles that seamlessly align with our clients' unique identities.
              <br></br>
              <br></br>
              Our primary objective is to expediently deliver the latest trends to retail shelves. Leveraging cutting-edge 3D implementation projects, we streamline each phase of the design process, facilitating rapid action and efficient execution.
        </p>
      </div>
      <div>
        <h1 className='text-xl md:text-3xl font-bold my-5 text-center md:text-start'>Manufacturing & delivery</h1>
        <p className='text-[10px] md:text-sm'>
              At the core of our ethos lies a commitment to excellence, operational efficiency, comprehensive fabric and garment scrutiny, and sustainable practices. Throughout every stage of our production pipeline, we prioritize the adoption of optimal and eco-conscious solutions to minimize unnecessary consumption of time, labor, energy, and resources. Embracing technological advancements, we continually invest in upgrades and adaptations to elevate our sustainability standards.
              <br></br>
              <br></br>
              A cutting-edge ERP system has been seamlessly integrated to optimize resource allocation transparently and efficiently, enabling us to deliver unparalleled quality promptly and cost-effectively.
              <br></br>
              <br></br>
              Our dedicated quality control team diligently oversees the meticulous inspection of every fabric and garment, ensuring a flawless standard of excellence. With our meticulously organized facilities, we expedite the delivery of top-tier quality products without compromising on efficiency.
        </p>
      </div>
    </section>
  )
}

export default Services